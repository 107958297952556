<script lang="ts">
  import Icon from '@iconify/svelte'
  import {
    Accordion,
    AccordionItem,
    Autocomplete,
    type AutocompleteOption,
    InputChip,
    popup,
    type PopupSettings,
    ProgressRadial,
  } from '@skeletonlabs/skeleton'
  import { getModalStore, getToastStore } from '@skeletonlabs/skeleton'
  import { onMount, type SvelteComponent } from 'svelte'

  import {
    datasetMetadataTypesValues,
    displayLabelingTypes,
    displayMetadataTypes,
    HirundoCSV,
    type IOptimizationDataset,
    isCOCO,
    isHirundoCSV,
    isYOLO,
    LabelingType,
    labelingTypesValues,
    runOptimization,
  } from './optimization-datasets-store'
  import Select from '@components/Select.svelte'
  import IntegrationFormFields from '@routes/storage-integrations/IntegrationFormFields.svelte'
  import {
    createStorageIntegration,
    getStoragePrefix,
    type IStorageIntegration,
    prepareInputValuesForQuery,
    storageIntegrationStore,
  } from '@routes/storage-integrations/storage-integrations-store'
  import {
    organizationAsyncState,
    organizationsStore,
  } from '@src/routes/organizations/organizations-store'
  import { loggedInStore, User } from '@stores/logged-in-store'
  import { ClassesOption, NAME_PATTERN } from '@utils'

  interface Props {
    /** Exposes parent props to this component. */
    parent: SvelteComponent
  }

  let { parent }: Props = $props()

  const modalStore = getModalStore()
  const toastStore = getToastStore()

  const currentUser: User = $loggedInStore

  let optimization: IOptimizationDataset | null = $modalStore[0].value
  let currentOrganizationId = optimization?.organizationId ?? currentUser.primaryOrganizationId
  let selectedIntegration: IStorageIntegration | null = $state(null) // For selected integration in search input
  let datasetFormElement: HTMLFormElement | undefined = $state()

  type OptimizationInput = Partial<
    Pick<IOptimizationDataset, 'name' | 'labelingType' | 'language' | 'organizationId'>
  > &
    Pick<IOptimizationDataset, 'labelingInfo'> & {
      classes: string[]
      selectedIntegrationId?: number | null
      selectedIntegrationName: string
    }

  let optimizationInputValues: OptimizationInput = $state({
    name: '',
    organizationId: currentOrganizationId,
    selectedIntegrationName: '',
    labelingInfo: new HirundoCSV(''),
    classes: [],
  })

  let sortedIntegrations = $derived(
    Array.from($storageIntegrationStore.values()).sort((a, b) => a.name.localeCompare(b.name)),
  )

  let selectedClassesOption: ClassesOption = $state(ClassesOption.AutoDetection)
  let isAccordionOpen = $state(false)
  let isEditMode = $state(false)
  let isLoading = $state(false)

  // Values for the IntegrationFormFields component
  let integrationFormElement: HTMLFormElement | undefined = $state()
  let integration = optimization?.storageIntegration
  let selectedStorageType = $state(integration?.type || null)
  let integrationName = $state(integration?.name || '')
  let storageInputValues = $state({}) // TODO: type more strictly

  onMount(async () => {
    await initializeInputValues(optimization)
  })

  const initializeInputValues = async (optimization: IOptimizationDataset | null) => {
    if (!optimization) return

    selectedIntegration = optimization.storageIntegration
    isEditMode = true // Disables the accordion and search input
    if ((optimization.classes?.length ?? 0) > 0) selectedClassesOption = ClassesOption.ManualInput

    const {
      name,
      labelingType,
      language,
      organizationId,
      labelingInfo,
      classes = [],
    } = optimization

    optimizationInputValues = {
      name,
      labelingType,
      language,
      organizationId,
      selectedIntegrationId: selectedIntegration?.id,
      selectedIntegrationName: selectedIntegration?.name,
      labelingInfo,
      classes: classes || [],
    }
  }

  const submitIntegrationFields = async () => {
    if (!integrationFormElement?.reportValidity()) return
    if (!selectedStorageType) {
      console.error('No storage type selected')
      return
    }

    const integrationPayload = prepareInputValuesForQuery(storageInputValues, selectedStorageType)
    integrationPayload.organizationId = currentOrganizationId
    integrationPayload.name = integrationName
    integrationPayload.type = selectedStorageType

    const newIntegration = await createStorageIntegration(
      integrationPayload,
      toastStore,
      currentUser.userId,
      currentUser.username,
    )
    if (!newIntegration) {
      console.error('Failed to create new integration')
      return
    }
    return newIntegration
  }

  const createDataset = async () => {
    if (isAccordionOpen) {
      const integrationCreated = await submitIntegrationFields()
      if (!integrationCreated) {
        console.error('Failed to create new integration from datsaset form')
        return
      }
      optimizationInputValues.selectedIntegrationId = integrationCreated.id
    }
    const payload = {
      name: optimizationInputValues.name,
      organizationId: optimizationInputValues.organizationId,
      labelingType: optimizationInputValues.labelingType,
      ...(optimizationInputValues.labelingType == LabelingType.SpeechToText
        ? { language: optimizationInputValues.language }
        : {}),
      storageIntegrationId: optimizationInputValues.selectedIntegrationId,
      labelingInfo: optimizationInputValues.labelingInfo,
      classes: optimizationInputValues.classes,
    }

    if (!$modalStore[0].response) {
      console.error("Modal doesn't have a response function")
      return
    }
    await $modalStore[0].response({ payload })
  }

  const createHandler = async () => {
    if (!datasetFormElement?.reportValidity()) return
    isLoading = true
    await createDataset()
    isLoading = false
    parent.onClose()
  }

  const createAndRunHandler = async () => {
    if (!datasetFormElement?.reportValidity()) return
    isLoading = true
    await createDataset()
    const datasetId = $modalStore[0].meta.optimizationId
    if (!datasetId) {
      console.error('Failed to create dataset. Could not run optimization')
      isLoading = false
      return
    }
    await runOptimization(datasetId, toastStore, optimizationInputValues.name)
    isLoading = false
    parent.onClose()
  }

  const onIntegrationSelect = (event: CustomEvent<AutocompleteOption<string>>) => {
    const selectedIntegrationName = event.detail.value
    const foundIntegration = sortedIntegrations.find(
      (integration) => integration.name === selectedIntegrationName,
    )

    if (foundIntegration) {
      selectedIntegration = foundIntegration
      isAccordionOpen = false
      optimizationInputValues = {
        ...optimizationInputValues,
        selectedIntegrationId: foundIntegration.id,
        selectedIntegrationName: foundIntegration.name,
      }
    }
  }

  let accordionDisabled = $derived(selectedIntegration !== null || isEditMode)

  function handleSearchInput(event: Event) {
    const input = (event.target as HTMLInputElement).value
    if (!input) {
      selectedIntegration = null // Reset the integration
      isAccordionOpen = false // Ensure accordion can be reopened
    }
  }

  const sharedInputClasses = 'input my-0 box-border flex w-fit min-w-96 px-2 py-1'

  const integrationsListPopup: PopupSettings = {
    event: 'focus-click',
    target: 'popupAutocomplete',
    placement: 'top',
  }

  const chooseIntegrationPopupHover: PopupSettings = {
    event: 'hover',
    placement: 'right',
    target: 'chooseIntegrationPopupHover',
  }

  const disabledEditDatasetStoragePopup: PopupSettings = {
    event: 'hover',
    placement: 'top',
    target: 'disabledEditDatasetStorage',
  }
</script>

{#if $modalStore[0]}
  <form
    class="card relative m-3 h-[30rem] w-[38rem] max-w-[38rem] justify-self-center overflow-y-scroll p-6"
    bind:this={datasetFormElement}
    class:dimmed={isLoading}
  >
    {#if isLoading}
      <div
        class="fixed left-0 top-0 z-50 flex h-full w-full items-center justify-center bg-secondary-400 bg-opacity-20"
      >
        <ProgressRadial
          stroke={75}
          meter="stroke-surface-200"
          track="stroke-surface-200/30"
          strokeLinecap="round"
          indeterminate={true}
        />
      </div>
    {/if}
    <h3 class="h3 justify-between">
      {$modalStore[0].title}
      <button
        class="absolute right-2 top-2 text-surface-400 hover:text-surface-300"
        type="button"
        onclick={parent.onClose}
      >
        <Icon icon="iconoir:xmark-circle" />
      </button>
    </h3>
    <span>{$modalStore[0].body}</span>

    <label for="name">Optimization name:</label>
    <input
      class={sharedInputClasses + ' autocomplete'}
      type="text"
      id="name"
      bind:value={optimizationInputValues.name}
      required
      pattern={NAME_PATTERN.source}
    />

    <label for="organization-to-associate-with">Associated organization:</label>
    {#if $organizationAsyncState.loading}
      <p>Loading organizations...</p>
    {:else if $organizationAsyncState.error}
      <p>{$organizationAsyncState.error}</p>
    {:else}
      <Select
        required
        name="organization-to-associate-with"
        bind:value={optimizationInputValues.organizationId}
      >
        <option selected value>Select organization</option>
        {#each $organizationsStore as [_id, organization] (organization.id)}
          <option
            value={organization.id}
            selected={organization.id === optimizationInputValues.organizationId}
          >
            {organization.name}
          </option>
        {/each}
      </Select>
    {/if}

    <label for="labeling-type">Labeling type:</label>
    <Select
      name="labeling-type"
      placeholder="Select Type"
      required
      bind:value={optimizationInputValues.labelingType}
    >
      <option selected value>Select type</option>
      {#each labelingTypesValues as labelingType}
        <option value={labelingType}>{displayLabelingTypes[labelingType]}</option>
      {/each}
    </Select>

    <label for="dataset-storage">
      Dataset storage:
      <button disabled class="[&>*]:pointer-events-none" use:popup={chooseIntegrationPopupHover}>
        <Icon icon="iconoir:help-circle" class="text-tertiary-400" />
      </button>
      <div class="card variant-filled-tertiary z-50 p-2" data-popup="chooseIntegrationPopupHover">
        <p>Select existing integration or create a new one</p>
        <div class="variant-filled-tertiary arrow"></div>
      </div>
    </label>
    <div class="relative" use:popup={disabledEditDatasetStoragePopup}>
      <div
        class={`card variant-filled-warning p-2 ${isEditMode ? 'visible' : 'invisible'}`}
        data-popup={'disabledEditDatasetStorage'}
      >
        <p>You cannot change the dataset storage in edit mode</p>
        <div class="variant-filled-warning arrow"></div>
      </div>
      <input
        class={sharedInputClasses}
        type="search"
        name="autocomplete-search"
        placeholder="Search..."
        disabled={isAccordionOpen || isEditMode}
        autocomplete="off"
        required={!isAccordionOpen}
        bind:value={optimizationInputValues.selectedIntegrationName}
        use:popup={integrationsListPopup}
        oninput={handleSearchInput}
      />
      <div
        class="card variant-filled-surface absolute z-50 max-h-48 w-full max-w-sm overflow-y-auto p-4"
        tabindex="-1"
        data-popup="popupAutocomplete"
      >
        <Autocomplete
          bind:input={optimizationInputValues.selectedIntegrationName}
          options={sortedIntegrations.map((integration) => ({
            value: integration.name,
            label: integration.name + ' (' + integration.type + ')',
          }))}
          on:selection={onIntegrationSelect}
          on:input={handleSearchInput}
        />
      </div>
      <div class="mt-4" class:disable-accordion={accordionDisabled}>
        <Accordion class="card variant-glass-surface">
          <AccordionItem
            on:click={() => (isAccordionOpen = !isAccordionOpen)}
            disabled={accordionDisabled}
          >
            {#snippet summary()}
              Create new integration
            {/snippet}
            {#snippet iconOpen()}
              <Icon icon="iconoir:plus" />
            {/snippet}
            {#snippet iconClosed()}
              <Icon icon="iconoir:minus" />
            {/snippet}
            {#snippet content()}
              <IntegrationFormFields
                bind:integrationFormElement
                bind:inputValues={storageInputValues}
                bind:selectedStorageType
                bind:integrationName
                {integration}
              ></IntegrationFormFields>
            {/snippet}
          </AccordionItem>
        </Accordion>
      </div>
    </div>

    <label for="metadataType">Metadata Type:</label>
    <Select
      name="metadataType"
      placeholder="Select Type"
      required
      bind:value={optimizationInputValues.labelingInfo.type}
    >
      <option selected value>Select type</option>
      {#each datasetMetadataTypesValues as metadataType}
        <option value={metadataType}>{displayMetadataTypes[metadataType]}</option>
      {/each}
    </Select>

    {#if isHirundoCSV(optimizationInputValues.labelingInfo, optimizationInputValues.labelingInfo.type)}
      <label for="csvUrl">CSV URL:</label>
      <div class="input input-group input-group-divider grid-cols-[auto_1fr]">
        <div class="input-group-shim">
          {getStoragePrefix({
            storageInputValues,
            selectedIntegration,
            type: selectedStorageType ?? selectedIntegration?.type,
          })}
        </div>
        <input
          class={sharedInputClasses}
          type="text"
          id="csvUrl"
          value={optimizationInputValues.labelingInfo.csvUrl.replace(
            getStoragePrefix({
              storageInputValues,
              selectedIntegration,
              type: selectedStorageType ?? selectedIntegration?.type,
            }),
            '',
          )}
          onchange={(e) => {
            if (
              isHirundoCSV(
                optimizationInputValues.labelingInfo,
                optimizationInputValues.labelingInfo.type,
              )
            ) {
              optimizationInputValues.labelingInfo.csvUrl =
                getStoragePrefix({
                  storageInputValues,
                  selectedIntegration,
                  type: selectedStorageType ?? selectedIntegration?.type,
                }) + e.currentTarget.value
            }
          }}
          required
        />
      </div>
    {:else if isCOCO(optimizationInputValues.labelingInfo, optimizationInputValues.labelingInfo.type)}
      <label for="jsonUrl">JSON URL:</label>
      <div class="input input-group input-group-divider grid-cols-[auto_1fr]">
        <div class="input-group-shim">
          {getStoragePrefix({
            storageInputValues,
            selectedIntegration,
            type: selectedStorageType ?? selectedIntegration?.type,
          })}
        </div>
        <input
          class={sharedInputClasses}
          type="text"
          id="jsonUrl"
          value={optimizationInputValues.labelingInfo.jsonUrl.replace(
            getStoragePrefix({
              storageInputValues,
              selectedIntegration,
              type: selectedStorageType ?? selectedIntegration?.type,
            }),
            '',
          )}
          onchange={(e) => {
            if (
              isCOCO(
                optimizationInputValues.labelingInfo,
                optimizationInputValues.labelingInfo.type,
              )
            ) {
              optimizationInputValues.labelingInfo.jsonUrl =
                getStoragePrefix({
                  storageInputValues,
                  selectedIntegration,
                  type: selectedStorageType ?? selectedIntegration?.type,
                }) + e.currentTarget.value
            }
          }}
          required
        />
      </div>
    {:else if isYOLO(optimizationInputValues.labelingInfo, optimizationInputValues.labelingInfo.type)}
      <label for="dataYamlUrl">Data YAML URL:</label>
      <div class="input input-group input-group-divider grid-cols-[auto_1fr]">
        <div class="input-group-shim">
          {getStoragePrefix({
            storageInputValues,
            selectedIntegration,
            type: selectedStorageType ?? selectedIntegration?.type,
          })}
        </div>
        <input
          class={sharedInputClasses}
          type="text"
          id="dataYamlUrl"
          value={optimizationInputValues.labelingInfo.dataYamlUrl.replace(
            getStoragePrefix({
              storageInputValues,
              selectedIntegration,
              type: selectedStorageType ?? selectedIntegration?.type,
            }),
            '',
          )}
          onchange={(e) => {
            if (
              isYOLO(
                optimizationInputValues.labelingInfo,
                optimizationInputValues.labelingInfo.type,
              )
            ) {
              optimizationInputValues.labelingInfo.dataYamlUrl =
                getStoragePrefix({
                  storageInputValues,
                  selectedIntegration,
                  type: selectedStorageType ?? selectedIntegration?.type,
                }) + e.currentTarget.value
            }
          }}
          required
        />
      </div>
      <label for="labelsDirUrl">Labels directory URL:</label>
      <div class="input input-group input-group-divider grid-cols-[auto_1fr]">
        <div class="input-group-shim">
          {getStoragePrefix({
            storageInputValues,
            selectedIntegration,
            type: selectedStorageType ?? selectedIntegration?.type,
          })}
        </div>
        <input
          class={sharedInputClasses}
          type="text"
          id="labelsDirUrl"
          value={optimizationInputValues.labelingInfo.labelsDirUrl.replace(
            getStoragePrefix({
              storageInputValues,
              selectedIntegration,
              type: selectedStorageType ?? selectedIntegration?.type,
            }),
            '',
          )}
          onchange={(e) => {
            if (
              isYOLO(
                optimizationInputValues.labelingInfo,
                optimizationInputValues.labelingInfo.type,
              )
            ) {
              optimizationInputValues.labelingInfo.labelsDirUrl =
                getStoragePrefix({
                  storageInputValues,
                  selectedIntegration,
                  type: selectedStorageType ?? selectedIntegration?.type,
                }) + e.currentTarget.value
            }
          }}
          required
        />
      </div>
    {/if}

    {#if optimizationInputValues.labelingType == LabelingType.SpeechToText}
      <label for="language">Language:</label>
      <Select
        name="language"
        placeholder="Select Language"
        required
        bind:value={optimizationInputValues.language}
      >
        <option value="ar">Arabic</option>
        <option value="he">Hebrew</option>
      </Select>
    {:else}
      <label for="classes">Classes:</label>
      <div class="space-y-2">
        <label class="flex items-center space-x-2">
          <input
            class="radio"
            type="radio"
            checked={selectedClassesOption === ClassesOption.AutoDetection}
            name="radio-direct"
            value="1"
            onchange={() => {
              selectedClassesOption = ClassesOption.AutoDetection
              optimizationInputValues.classes = []
            }}
          />
          <p>Auto-detection</p>
        </label>
        <label class="flex items-center space-x-2">
          <input
            class="radio"
            type="radio"
            name="radio-direct"
            value="2"
            checked={selectedClassesOption === ClassesOption.ManualInput}
            onchange={() => (selectedClassesOption = ClassesOption.ManualInput)}
          />
          <InputChip
            bind:value={optimizationInputValues.classes}
            name="chips"
            placeholder="Type a class and hit Enter "
            disabled={selectedClassesOption === ClassesOption.AutoDetection}
          />
        </label>
      </div>
    {/if}

    <footer class="modal-footer {parent.regionFooter}">
      <div class="flex w-full items-center justify-between">
        <div class="btn-group space-x-2">
          <button
            class="variant-filled-primary {parent.buttonPositive}"
            type="button"
            onclick={createHandler}
            disabled={isLoading}
          >
            {parent.buttonTextSubmit}
          </button>
          <button
            class="variant-filled-error"
            type="button"
            onclick={parent.onClose}
            disabled={isLoading}
          >
            {parent.buttonTextCancel}
          </button>
        </div>
        {#if !isEditMode}
          <div class="btn-group">
            <button
              class="variant-filled-primary"
              type="button"
              onclick={createAndRunHandler}
              disabled={isLoading}
            >
              <Icon icon="iconoir:play-solid" />
            </button>
          </div>
        {/if}
      </div>
    </footer>
  </form>
{/if}

<style lang="postcss">
  form {
    flex-direction: column;
    width: fit-content;
    min-width: 30rem;
  }
  form > * {
    margin: 0.5rem 0;
  }
  input[type='search']::-webkit-search-cancel-button {
    filter: invert(1); /* Invert to white if the background is dark */
  }
  .disable-accordion {
    @apply pointer-events-none cursor-not-allowed opacity-50;
  }
</style>

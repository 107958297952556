<script lang="ts">
  import Icon from '@iconify/svelte'
  import { getModalStore } from '@skeletonlabs/skeleton'
  import type { SvelteComponent } from 'svelte'

  import IntegrationFormFields from './IntegrationFormFields.svelte'
  import { prepareInputValuesForQuery } from './storage-integrations-store'
  import { loggedInStore } from '@stores/logged-in-store'

  interface Props {
    /** Exposes parent props to this component. */
    parent: SvelteComponent
  }

  let { parent }: Props = $props()

  const modalStore = getModalStore()

  let integration = $modalStore[0].value
  let selectedStorageType = $state(integration?.type || null)
  let integrationName = $state(integration?.name || '')
  let currentOrganizationId = integration?.organizationId || $loggedInStore.primaryOrganizationId

  let inputValues = $state({})
  let integrationFormElement: HTMLFormElement | undefined = $state()

  const submitHandler = () => {
    if (!integrationFormElement?.reportValidity()) return
    if (!selectedStorageType) {
      console.error('Tried to create a storage integration without a storage type')
      return
    }

    const payload = prepareInputValuesForQuery(inputValues, selectedStorageType)
    payload.organizationId = currentOrganizationId
    payload.name = integrationName
    payload.type = selectedStorageType

    if (!$modalStore[0].response) {
      console.error("Modal doesn't have a response function")
      return
    }
    $modalStore[0].response({ payload, closeModal: parent.onClose })
  }
</script>

{#if $modalStore[0]}
  <div
    class="card relative m-3 h-[30rem] w-[38rem] max-w-[38rem] space-y-4 justify-self-center overflow-y-scroll p-6"
  >
    <h3 class="h3 justify-between">
      {$modalStore[0].title}
      <button
        class="absolute right-2 top-2 text-surface-400 hover:text-surface-300"
        type="button"
        onclick={parent.onClose}
      >
        <Icon icon="iconoir:xmark-circle" />
      </button>
    </h3>
    <span>{$modalStore[0].body}</span>
    <IntegrationFormFields
      bind:integrationFormElement
      bind:inputValues
      bind:selectedStorageType
      bind:integrationName
      {integration}
    ></IntegrationFormFields>
    <footer class="modal-footer {parent.regionFooter}">
      <div class="variant-ghost-surface btn-group w-min">
        <button
          class="variant-filled-primary {parent.buttonPositive}"
          type="submit"
          onclick={submitHandler}
        >
          {parent.buttonTextSubmit}
        </button>
        <button class="variant-filled-error" type="button" onclick={parent.onClose}>
          Cancel
        </button>
      </div>
    </footer>
  </div>
{/if}

<style>
  .card {
    flex-direction: column;
    width: fit-content;
    min-width: 30rem;
  }
  .card > * {
    margin: 0.5rem 0;
  }
</style>

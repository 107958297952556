<script lang="ts">
  import { page } from '$app/stores'
  import type { Snippet } from 'svelte'

  interface Props {
    path?: string | undefined
    onclick?: () => void
    children?: Snippet
  }

  let { path = undefined, onclick, children }: Props = $props()
</script>

{#if path}
  <li aria-current={$page.url.pathname === path ? 'page' : undefined}>
    <a href={path}>{@render children?.()}</a>
  </li>
{:else}
  <li>
    <button {onclick}>{@render children?.()}</button>
  </li>
{/if}

<style>
  li {
    position: relative;
    height: 100%;
  }

  li[aria-current='page']::before {
    --size: 6px;
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    top: 0;
    left: calc(50% - var(--size));
    border: var(--size) solid transparent;
    border-top: var(--size) solid var(--color-theme-1);
  }

  li a,
  li button {
    display: flex;
    height: 100%;
    align-items: center;
    padding: 0 0.5rem;
    color: var(--color-text-inverse);
    font-weight: 700;
    font-size: 0.8rem;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    text-decoration: none;
    transition: color 0.2s linear;
  }

  li button {
    background: none;
    border: none;
    cursor: pointer;
  }

  li a:hover,
  li button:hover {
    color: var(--color-theme-1);
  }
</style>
